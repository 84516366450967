import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Section } from '../components/section';
import { Anchors } from '../components/navigation';
import styles from './index.module.scss';

const IndexPage = ({ location }: { location: Location }) => (
  <Layout location={location}>
    <SEO title="SimpleSoft software house - DevOps job" description="job offer, DevOps, Azure, Terraform, Gitlab, SQL Server, Linux, Kubernetes" />
    <Section anchor={''}>
      <h1>Trainee</h1>
        <h3>Job description</h3>
        <p>
        We are looking for a person to join our team who will help its growth and future development. 
        In return we are giving a fantastic opportunity for expanding your own knowledge and gain more experience. 
        As an employee you will be responsible for creating software in below technologies:
        </p>
        <h4>Tech stack:</h4>
        <ul>
          <li>.NET C#/F#</li>
          <li>Angular</li>
          <li>React & Redux</li>
          <li>Typescript</li>
        </ul>
    </Section>
  </Layout>
);

export default IndexPage;
